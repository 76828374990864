import SignIn from "./SignIn";
import Main from "./Main";
import { CssBaseline, Snackbar } from "@material-ui/core";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { useState, useEffect, useRef } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import io from "socket.io-client";

// PROD
//const SERVER_ENDPOINT = "https://prs.hcom.co.nz:4001";
// REMOTE DEV
const SERVER_ENDPOINT = "https://midge.hcom.co.nz:4001";
// LOCAL DEV
//const SERVER_ENDPOINT = "http://192.168.178.29:4001";

function App() {
  // Use context provider instead?
  const ioRef = useRef();

  const [app, setApp] = useState({
    config: undefined,
    signedIn: false,
    signingIn: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
    duration: 3000,
  });

  useEffect(() => {
    ioRef.current = io(SERVER_ENDPOINT);

    const reset = () => {
      setApp({
        config: undefined,
        signedIn: false,
        signingIn: false,
      });
    };

    ioRef.current.on("connect", () => {
      setSnackbar({
        open: true,
        message: "Connected to Server",
        severity: "success",
        duration: 2000,
      });
    });

    ioRef.current.on("connect_error", (err) => {
      console.log("connect_error: ", err.message);
      reset();
      setError("Disconnected from Server");
    });

    ioRef.current.on("connect_timeout", (timeout) => {
      console.log("connect_timeout: ", timeout);
      reset();
      setError("Disconnected from Server");
    });

    ioRef.current.on("error", (err) => {
      const { message } = err;
      console.log("error: ", message);
      reset();
      setError(message);
    });

    ioRef.current.on("disconnect", (reason) => {
      console.log("disconnect: ", reason);
      reset();
    });

    ioRef.current.on("reconnecting", (attempt) => {
      console.log("reconnecting: ", attempt);
    });

    ioRef.current.on("reconnect_error", (err) => {
      console.log("reconnect_error: ", err.message);
    });

    ioRef.current.on("authentication-result", (config) => {
      if (!config) {
        setError("Sign in failed");
      }

      setApp({ config, signedIn: !!config, signingIn: false });
    });

    return () => {
      ioRef.current.disconnect();
      ioRef.current.off("connect");
      ioRef.current.off("connect_error");
      ioRef.current.off("connect_timeout");
      ioRef.current.off("error");
      ioRef.current.off("disconnect");
      ioRef.current.off("reconnecting");
      ioRef.current.off("reconnect_error");
      ioRef.current.off("authentication-result");
    };
  }, []);

  const handleSignIn = (pwd) => {
    setApp({ ...app, signingIn: true });
    ioRef.current.emit("authenticate", pwd);
  };

  const handleSignOut = () => {
    setApp({
      signedIn: false,
      signingIn: false,
      isConnected: ioRef.current.connected,
    });
  };

  const setError = (message) => {
    setSnackbar({ open: true, message, severity: "error", duration: 4000 });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!app.signedIn && <SignIn signingIn={app.signingIn} onSignIn={handleSignIn} />}
      {app.signedIn && (
        <Main
          config={app.config}
          io={ioRef.current}
          isConnected={app.isConnected}
          onError={setError}
          onSignOut={handleSignOut}
        />
      )}
      <Snackbar open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
